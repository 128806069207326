





























import Vue from "vue";
import Cookies from "js-cookie";
import japan from "./json/japan.json";
const prefs = [
  "全国",
  "北海道",
  "青森",
  "岩手",
  "宮城",
  "秋田",
  "山形",
  "福島",
  "茨城",
  "栃木",
  "群馬",
  "埼玉",
  "千葉",
  "東京",
  "神奈川",
  "新潟",
  "富山",
  "石川",
  "福井",
  "山梨",
  "長野",
  "岐阜",
  "静岡",
  "愛知",
  "三重",
  "滋賀",
  "京都",
  "大阪",
  "兵庫",
  "奈良",
  "和歌山",
  "鳥取",
  "島根",
  "岡山",
  "広島",
  "山口",
  "徳島",
  "香川",
  "愛媛",
  "高知",
  "福岡",
  "佐賀",
  "長崎",
  "熊本",
  "大分",
  "宮崎",
  "鹿児島",
  "沖縄",
];
const lomaPrefs = [
  "Hokkaido",
  "Aomori",
  "Iwate",
  "Miyagi",
  "Akita",
  "Yamagata",
  "Fukushima",
  "Ibaraki",
  "Tochigi",
  "Gunma",
  "Saitama",
  "Chiba",
  "Tokyo",
  "Kanagawa",
  "Niigata",
  "Toyama",
  "Ishikawa",
  "Fukui",
  "Yamanashi",
  "Nagano",
  "Gifu",
  "Shizuoka",
  "Aichi",
  "Mie",
  "Shiga",
  "Kyoto",
  "Osaka",
  "Hyogo",
  "Nara",
  "Wakayama",
  "Tottori",
  "Shimane",
  "Okayama",
  "Hiroshima",
  "Yamaguchi",
  "Tokushima",
  "Kagawa",
  "Ehime",
  "Kochi",
  "Fukuoka",
  "Saga",
  "Nagasaki",
  "Kumamoto",
  "Oita",
  "Miyazaki",
  "Kagoshima",
  "Okinawa",
];
const urls: string[] = [
  "/",
  "/hokkaido",
  "/aomori",
  "/iwate",
  "/miyagi",
  "/akita",
  "/yamagata",
  "/fukushima",
  "/ibaraki",
  "/tochigi",
  "/gunma",
  "/saitama",
  "/chiba",
  "/tokyo",
  "/kanagawa",
  "/niigata",
  "/toyama",
  "/ishikawa",
  "/fukui",
  "/yamanashi",
  "/nagano",
  "/gifu",
  "/shizuoka",
  "/aichi",
  "/mie",
  "/shiga",
  "/kyoto",
  "/osaka",
  "/hyogo",
  "/nara",
  "/wakayama",
  "/tottori",
  "/shimane",
  "/okayama",
  "/hiroshima",
  "/yamaguchi",
  "/tokushima",
  "/kagawa",
  "/ehime",
  "/kochi",
  "/fukuoka",
  "/saga",
  "/nagasaki",
  "/kumamoto",
  "/oita",
  "/miyazaki",
  "/kagoshima",
  "/okinawa",
];
const prefsArea = [
  [1, 43.03, 141.21],
  [2, 40.49, 140.44],
  [3, 39.42, 141.09],
  [4, 38.16, 140.52],
  [5, 39.43, 140.06],
  [6, 38.15, 140.2],
  [7, 37.45, 140.28],
  [8, 36.22, 140.28],
  [9, 36.33, 139.53],
  [10, 36.23, 139.03],
  [11, 35.51, 139.38],
  [12, 35.36, 140.06],
  [13, 35.41, 139.45],
  [14, 35.26, 139.38],
  [15, 37.55, 139.02],
  [16, 36.41, 137.13],
  [17, 36.33, 136.39],
  [18, 36.03, 136.13],
  [19, 35.39, 138.34],
  [20, 36.39, 138.11],
  [21, 35.25, 136.45],
  [22, 34.58, 138.23],
  [23, 35.11, 136.54],
  [24, 34.43, 136.3],
  [25, 35.0, 135.52],
  [26, 35.0, 135.46],
  [27, 34.41, 135.29],
  [28, 34.41, 135.11],
  [29, 34.41, 135.48],
  [30, 34.14, 135.1],
  [31, 35.29, 134.13],
  [32, 35.27, 133.04],
  [33, 34.39, 133.54],
  [34, 34.23, 132.27],
  [35, 34.11, 131.27],
  [36, 34.03, 134.32],
  [37, 34.2, 134.02],
  [38, 33.5, 132.44],
  [39, 33.33, 133.31],
  [40, 33.35, 130.23],
  [41, 33.16, 130.16],
  [42, 32.45, 129.52],
  [43, 32.48, 130.42],
  [44, 33.14, 131.37],
  [45, 31.56, 131.25],
  [46, 31.36, 130.33],
  [47, 26.13, 127.41],
];
type State = {
  lomaPrefs: string[];
  japan: any;
  prefs: string[];
  pref: number;
  active: number;
  latitude?: number;
  longitude?: number;
};
export default Vue.extend({
  data(): State {
    const cookiePref = Cookies.get("jtown-pref");
    return {
      lomaPrefs,
      prefs,
      japan,
      active: 0,
      latitude: undefined,
      longitude: undefined,
      pref: cookiePref !== undefined ? Number(cookiePref) : 0,
    };
  },
  computed: {
    areaUrl(): string {
      if (this.pref) {
        const pref = lomaPrefs[this.pref - 1].toLowerCase();
        return `/assets/area/img_area_map/img_area_${pref}.svg`;
      } else if (this.pref == 0) {
        return `/assets/area/img_area_map/img_area_all.svg`;
      }
      return "";
    },
  },
  methods: {
    setPref(pref: number) {
      this.pref = pref;
    },
    goto() {
      Cookies.set("jtown-pref", String(this.pref), { expires: 28 });
      document.location.href = urls[this.pref];
    },
  },
});
